import React from "react";
import Accordion from "../bodycomponents/accordion";

export default function WhySSLLCHomeComponent() {
  const accordionItems = [
    {
      title: "Seamless Buying & Selling",
      content:
        "We’ve perfected the buying and selling processes to make them as simple and seamless as possible. We can take care of everything, including appraisals, listings, logistics, and final transactions—keeping you in the loop at every step while you stay focused on the work in front of you.",
    },
    {
      title: "Flexible Buying & Selling",
      content:
        "Our services are designed to offer maximum flexibility to suit your unique needs. You can tailor your buying or selling experience according to your business requirements.",
    },
    {
      title: "Outstanding Equipment Quality",
      content:
        "All equipment we sell goes through rigorous quality checks to ensure it meets the highest standards and is ready to perform in the toughest environments.",
    },
  ];
  return (
    <>
      <div className="shadow-custom-out">
        <div className="container w-full max-w-screen-xl flex flex-col md:flex-row justify-between md:space-x-6 items-stretch py-16">
          <div className="w-full md:w-1/2 pr-0 md:pr-6 space-y-5 pb-4 md:pb-0">
            <h2 className="text-royal-blue">Why Choose Surplus Solutions?</h2>
            <p>
              It all comes down to value: The value of your time, your assets,
              and your business. We go beyond buying and selling lab and
              processing equipment, building flexible, future-focused
              partnerships matched to the ongoing needs of your organization.
              And we back up our strategic guidance with a vast inventory, an
              unmatched network of potential buyers, and a full portfolio of
              value-add services from appraisals to relocations to global
              logistics.
            </p>
            <p>
              Our certified specialists conduct in-depth analysis of every
              single piece of lab, processing, and packaging equipment that you
              plan to buy or sell. We partner with industry-leading
              manufacturers to deliver consistent quality and reliability. And
              our seamless, flexible processes ensure a hassle-free experience
              while maximizing return on your investment.
            </p>
          </div>
          <div className="w-full md:w-1/2 pl-0 md:pl-6 min-h-full">
            <Accordion items={accordionItems} />
          </div>
        </div>
      </div>
    </>
  );
}
