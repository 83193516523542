import React from "react";
import RatingCard from "./ratingcard";

export default function RatingComponent() {
  return (
    <>
      <div className="shadow-custom-in">
        <div className="container max-w-screen-xl py-16">
          <h2 className="text-center text-orange pb-6">What Our Clients Say</h2>
          <div className="grid gap-5 lg:grid-cols-3">
            {/* Card One */}
            <RatingCard
              body={
                <p>
                  SSLLC has been amazing. I have saved 90% by purchasing used
                  lab equipment at auction from them. What’s even better than
                  the prices is their dedicated and responsive staff. Payment
                  and pickup of items has been as easy as possible. Thanks so
                  much to Ali, Kim, Neil, and the guys at the warehouse for
                  everything!
                </p>
              }
              author="Head of Scientific Operations at a Cambridge Life Science Institute"
            />

            {/* Card Two */}
            <RatingCard
              body={
                <p>
                  I’ve had the pleasure of working with SSLLC over the last 18
                  months, and they have been a great company to work with. Adam
                  has proactively been on the lookout for equipment I’m in need
                  of, and even stayed after hours on a holiday weekend to ensure
                  a shipment went out. Highly recommended!
                </p>
              }
              author="
Global Director of CAPEX at an Israeli Food Startup"
            />

            {/* Card THree */}
            <RatingCard
              body={
                <p>
                  Fantastic experience working with SSLLC. They have been a huge
                  resource for setting up my new biotech company. All of the
                  equipment purchased has worked great and have all passed
                  calibration. We will definitely be a long term customer!!
                </p>
              }
              author="Chief Executive Officer of a Global Diagnostics Biotech"
            />
          </div>
        </div>
      </div>
    </>
  );
}
