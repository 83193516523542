import React from "react"
import BLUE_BG from "../../images/background-images/bg-blue.svg"
// import SurplusImage from "../../images/Surplus.jpg"
import SurplusImage1 from "../../images/2025-SSLLC-Website-Home-Page-Image-Final-31Jan2025.jpg"
import MenuComponent from "./menucomponent"
import BrowseByCat from "../bodycomponents/browsebycat"
import HomeAuctionComponent from "./homeauction"
import R_GRAY_BG from "../../images/background-images/bg-gray-reversed.svg"
import { colors } from "../../utils/colors"
import ContactForm from "../bodycomponents/contact-form"
import WhySSLLCHomeComponent from "./whyssllc"
import RatingComponent from "./rating"
import WhatsNewComponent from "./whatsnew"

const Main = () => {
  return (
    <>
      <div className="relative bg-radial-mobile md:bg-radial-desktop">
        {/* Simulating ::after */}
        <div
          className="absolute bg-left-top bg-cover bg-no-repeat !z-0"
          style={{
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundImage: `url(${BLUE_BG})`,
            backgroundSize: "90%",
            pointerEvents: "none",
          }}
        ></div>
        <div className="relative w-full h-auto md:h-[500px]">
          {/* Full Height Image */}
          <div className="relative md:absolute md:top-0 md:right-0 md:pl-10 w-full h-[250px] md:w-1/2 md:h-full pb-10 md:pb-0">
            <img
              src={SurplusImage1}
              alt="External shot of Surplus Solutions headquarters in Woonsocket, Rhode Island."
              className="w-full h-full object-cover"
            />
          </div>
          <div className="relative max-w-screen-xl mx-auto flex flex-col md:flex-row items-center h-full">
            {/* Left Section - Text */}
            <div className="w-full md:w-1/2 text-white space-y-4 flex flex-col pb-10 pl-10 lg:pl-20 xl:pl-10">
              <h1 className="text-balance lg:text-[3.4rem] mb-0">
                Buying or selling used lab equipment?
              </h1>
              <h2 className="font-light">We've got this down to a science.</h2>
            </div>
            {/* Right Section - Empty Placeholder for Layout */}
            <div className="hidden md:block w-1/2"></div>
          </div>
        </div>
      </div>

      <MenuComponent />
      <BrowseByCat
        title="Explore Product Categories"
        body={
          <p>
            Check out Surplus Solutions’ wide range of categories and
            comprehensive inventory of products.
          </p>
        }
      />
      <HomeAuctionComponent
        backgroundImage={R_GRAY_BG}
        backgroundColor={colors.lightGray}
      />

      <WhySSLLCHomeComponent />
      <RatingComponent />
      <WhatsNewComponent />
      <ContactForm
        title="Get in Touch with Surplus Solutions"
        body={
          <p>
            Have questions? Ready to create an effective plan for surplus asset
            management? Get in touch any time.
          </p>
        }
        backgroundColor={colors.containerGray}
        fontShadowColor={colors.red}
        shadow="shadow-red-bottom"
        formType="Contact"
      />
    </>
  )
}

export default Main
