import React from "react";
import AuctionImage from "../../images/explore/SSLLC-Services_-Sell-Equipment.jpg";
import { Link } from "gatsby";

const HomeAuctionComponent = (props) => {
  return (
    <div
    className="bg-cover py-20 shadow-custom-in"
      style={{
        backgroundColor: props.backgroundColor,
        backgroundImage: `URL(${props.backgroundImage})`,
      }}
    >
      <div className="container w-full max-w-screen-xl flex flex-col md:flex-row">
        {/* Left Image Section */}
        <div className="bg-red w-full md:w-2/5 flex items-center justify-center rounded-t-md md:rounded-l-md md:rounded-r-none">
            <img
              src={AuctionImage}
              alt="Modern laboratory interior out of focus, including equipment."
              className="w-full aspect-[5/4] object-cover p-6 md:ml-16 md:p-0 lg:py-6 xl:p-0 relative z-20"
            />
        </div>

        <div className="bg-white w-full md:w-3/5 flex flex-col p-10 md:pl-20 relative z-10 rounded-md rounded-l-none">
          <div>
            <h4 className="text-medium-gray uppercase pb-2">Auctions</h4>

            <h2 className="mb-4">Your Winning Bid Awaits</h2>
            <div className="pb-0 md:pb-14">
              <p>
                Here's your chance to bid on high-quality used lab equipment,
                and get exactly the laboratory, processing, and packaging
                equipment you’re looking for at a fraction of the retail price.
                We’re always updating our inventory, so check back often to find
                the kind of deals you want on everything you need.
              </p>
            </div>
          </div>
          <div className="mt-6">
            <Link
              to="/auctions"
              className="bg-royal-blue text-white text-[1.2rem] py-2 px-4 rounded hover:bg-blue hover:text-black"
            >
              View Auctions
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeAuctionComponent;
