import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

const WhatsNewComponent = () => {
  const data = useStaticQuery(graphql`
    query {
      blog: allBlogItem(sort: { fields: isoDate, order: DESC }, limit: 1) {
        edges {
          node {
            title
            pubDate
            isoDate
            link
            imageUrl
            imageAlt
          }
        }
      }
      conference: allConferenceItem(
        sort: { fields: isoDate, order: DESC }
        limit: 1
      ) {
        edges {
          node {
            title
            pubDate
            isoDate
            link
            imageUrl
            imageAlt
            startDate
            endDate
            city
            state
          }
        }
      }
      news: allNewsItem(sort: { fields: isoDate, order: DESC }, limit: 1) {
        edges {
          node {
            title
            pubDate
            isoDate
            link
            imageUrl
            imageAlt
          }
        }
      }
    }
  `)

  const latestBlog = data.blog.edges[0]?.node
  const latestConference = data.conference.edges[0]?.node
  const latestNews = data.news.edges[0]?.node

  const startDate = new Date(latestConference.startDate)
  const endDate = new Date(latestConference.endDate)
  const options = { year: "numeric", month: "short", day: "numeric" }

  const formattedStartDate = startDate.toLocaleDateString("en-US", options)
  const formattedEndDate = endDate.toLocaleDateString("en-US", options)

  return (
    <>
      <div className="bg-light-gray py-16 shadow-custom-in">
        <div className="container w-full max-w-screen-xl flex flex-col">
          <h2 className="text-royal-blue">Explore What's New</h2>
          {/* Blog */}
          <div className="flex flex-col md:flex-row w-full bg-white rounded-md overflow-hidden mt-8">
            <div className="w-full md:w-2/5">
              <img
                src={latestBlog.imageUrl}
                alt={latestBlog.imageAlt || "Image representing Latest Blog"}
                className="object-cover w-full h-full"
                loading="lazy"
              />
            </div>
            <div className="w-full md:w-3/5 p-8 justify-between flex flex-col items-start">
              <div className="w-full  ">
                <h6 className="text-medium-gray pb-2">FEATURED BLOG</h6>
                <h4 className="text-pretty">{latestBlog.title}</h4>
              </div>
              <Link
                to={latestBlog.link}
                className="bg-light-gray text-[1.2rem] text-charcoal hover:bg-royal-blue hover:text-white py-3 px-6 rounded-md mt-4 "
              >
                Read More &gt;
              </Link>
            </div>
          </div>

          {/* Events and News */}
          <div className="flex flex-col md:flex-row w-full mt-10 space-x-0 md:space-x-12 space-y-10 md:space-y-0 justify-between md:justify-stretch">
            {/* Event */}
            <div className="w-full md:w-1/2">
              <div className="flex flex-col lg:flex-row w-full bg-white rounded-md overflow-hidden h-full">
                <div className="w-full lg:w-2/5">
                  <img
                    src={latestConference.imageUrl}
                    alt={latestConference.imageAlt || "Image representing Latest Conference"}
                    className="object-contain w-full h-56 lg:h-full"
                    loading="lazy"
                  />
                </div>
                <div className="w-full lg:w-3/5 p-8 justify-between flex flex-col flex-grow items-start">
                  <div className="w-full pb-4" >
                    <h6 className="text-medium-gray pb-2">LATEST EVENTS</h6>
                    <h4 className="text-pretty">{latestConference.title}</h4>
                    <h6 className="text-royal-blue">
                      {formattedStartDate} - {formattedEndDate}
                    </h6>
                    <span className="font-light">
                      {latestConference.city}, {latestConference.state}
                    </span>
                    <br />
                  </div>
                  <Link
                    to={latestConference.link}
                    className="bg-light-gray text-[1.2rem] text-charcoal hover:bg-royal-blue hover:text-white py-3 px-4 rounded-md mt-4 w-full"
                  >
                    Read More &gt;
                  </Link>
                </div>
              </div>
            </div>
            {/* News */}
            <div className="w-full md:w-1/2">
              <div className="flex flex-col lg:flex-row w-full bg-white rounded-md overflow-hidden h-full">
                <div className="w-full lg:w-2/5">
                  <img
                    src={latestNews.imageUrl}
                    alt={latestNews.imageAlt || "Image representing Latest News"}
                    className="object-cover w-full h-56 lg:h-full"
                    loading="lazy"
                  />
                </div>
                <div className="w-full lg:w-3/5 p-8 justify-between flex flex-col flex-grow items-start">
                  <div className="w-full md:w-2/3">
                    <h6 className="text-medium-gray pb-2">LATEST NEWS</h6>
                    <h4 className="text-pretty">{latestNews.title}</h4>
                  </div>
                  <Link
                    to={latestNews.link}
                    className="bg-light-gray text-[1.2rem] text-charcoal hover:bg-royal-blue hover:text-white py-3 px-4 rounded-md mt-4 w-full"
                  >
                    Read More &gt;
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WhatsNewComponent