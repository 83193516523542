import React from "react"
import { Link } from "gatsby"
import RED_ARROW from "../../images/icons/SSLLC_Icons_Arrow_Crimson_Right.svg"
import BLUE_ARROW from "../../images/icons/SSLLC_Icons_Arrow_Royal-Blue_Left.svg"

export default function MenuComponent() {
  return (
    <div className="bg-light-gray py-14 shadow-custom-out relative z-20">
      <div className="container max-w-screen-xl">
        <p className="pb-5">
          Looking to buy or sell used lab equipment? Surplus Solutions is your
          global partner in surplus asset management, with more than 25 years of
          experience in the pharmaceutical and biotech industries and a
          comprehensive, easily accessible inventory of highly valued equipment.
        </p>
        <div className="grid lg:grid-cols-3 justify-between gap-12 pt-5">
          <div className="bg-white shadow-sm rounded-[5px] p-10 text-center items-center flex flex-col relative">
            <div
              style={{
                content: '""', // Simulates the `::before` pseudo-element
                position: "absolute",
                zIndex: 100,
                left: "-30px",
                top: "calc(1.875rem + 10px)",
                width: "80px",
                height: "80px",
                backgroundImage: `url(${RED_ARROW})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                pointerEvents: "none",
              }}
            ></div>
            <h2 className="text-balance mb-4">
              Buy with <br /> Confidence
            </h2>
            <div className="flex flex-grow">
              <p className="text-pretty mb-10">
                Our marketplace includes more than 120 categories of lab,
                packaging, and processing equipment, featuring countless models
                from more than 1,200 brands—with new inventory arriving every
                day. Which makes Surplus Solutions the best place to start when
                you’re looking for a specific item, a great deal, or both.
              </p>
            </div>
            <Link
              to="/buy-equipment"
              className="bg-red text-[1.2rem] text-white hover:text-white  py-2 px-5 rounded-[4px] hover:bg-scarlet-red"
            >
              Browse Equipment
            </Link>
          </div>

          <div className="bg-white shadow-sm rounded-[5px] p-10 text-center items-center flex flex-col">
            <h2 className="text-balance mb-4">
              Auction <br /> Savings
            </h2>
            <div className="flex flex-grow">
              <p className="text-pretty mb-10">
                Weekly online auctions provide exceptional deals on top-quality
                used laboratory, packaging, and processing equipment accessible
                worldwide. Whether replacing a single item or outfitting an
                entire facility, our auctions feature a diverse selection from
                leading manufacturers, with new equipment available weekly.
              </p>
            </div>
            <Link
              to="/auctions"
              className="bg-white border border-red text-[1.2rem] text-red py-2 px-5 rounded-[4px] hover:bg-red hover:text-white"
            >
              View Catalogs
            </Link>
          </div>

          <div className="bg-white shadow-sm rounded-[5px] p-10 text-center items-center flex flex-col relative">
            <div
              style={{
                content: '""', // Simulates the `::before` pseudo-element
                position: "absolute",
                zIndex: 100,
                right: "-30px",
                top: "calc(1.875rem + 10px)",
                width: "80px",
                height: "80px",
                backgroundImage: `url(${BLUE_ARROW})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                pointerEvents: "none", // Prevents interaction blocking
              }}
            ></div>
            <h2 className="text-balance mb-4">
              Sell for <br /> Maximum Value
            </h2>
            <div className="flex flex-grow">
              <p className="text-pretty mb-10">
                We have decades of experience assessing the true market value of
                specialized lab, packaging, and processing equipment, in all
                kinds of economic conditions. So you can count on our accurate,
                detailed appraisal reports to help you make the best decisions
                on how to handle your surplus equipment, whether that’s auction,
                direct sale, or immediate purchase.
              </p>
            </div>
            <Link
              to="/sell-equipment"
              className="bg-royal-blue text-[1.2rem] text-white hover:text-white py-2 px-5 rounded-[4px] hover:bg-blue"
            >
              Sell Equipment
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
