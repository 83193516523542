import React from "react";
import FIVE_STARS from "../../images/SSLLC_Icons_5-Star-Rating_Orange-2.svg";


export default function RatingCard(props) {
  return (
    <>
      <div className="w-full justify-between pb-6 px-3">
        <div className="bg-container-gray rounded-[15px] p-6 text-center flex flex-col h-full shadow-tangerine-bottom">
          <div className="mx-auto pb-4">
            <img src={FIVE_STARS} alt="Five orange stars arranged in a line" />
          </div>
          <div className="flex-grow flex text-balance pb-4">
           {props.body} 
            
          </div>
          <div className="text-balance">
            <h6>
              {props.author}
            </h6>
          </div>
        </div>
      </div>
    </>
  );
}
